/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { DestroyRef, Directive, ElementRef, HostListener, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[appCheckNameExisted]'
})
export class CheckNameExistedDirective {
  private readonly destroyRef = inject(DestroyRef);

  @Input() appCheckNameExisted!: { service: any; function?: string };
  formControlName!: string;
  timer!: ReturnType<typeof setTimeout>;

  @HostListener('keyup', ['$event']) onChange() {
    this.onInputChange();
  }

  constructor(
    private el: ElementRef,
    private rootForm: FormGroupDirective
  ) {}

  ngAfterViewInit(): void {
    let input: HTMLInputElement = this.el.nativeElement;
    let attribute: NamedNodeMap = input.attributes;
    this.formControlName = attribute.getNamedItem('formcontrolname')?.value || 'username';
  }

  onInputChange(): void {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.onCheck(this.rootForm.control.controls[this.formControlName]?.value), 500);
  }

  onCheck(value: string): void {
    this.appCheckNameExisted.service[this.appCheckNameExisted?.function ?? 'checkExistedUser']({
      username: value
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: any) => {
        if (res.user_exist == true) {
          this.rootForm.control.controls[this.formControlName].setErrors({ nameExist: true });
        }
      });
  }
}

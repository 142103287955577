import { inject, Pipe, PipeTransform } from '@angular/core';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'staticFile',
  standalone: true
})
export class StaticFilePipe implements PipeTransform {
  private downloadPath = '/client/file-data/download/';
  private readonly service = inject(LocalStorageService);
  transform(value: string, size?: 's' | 'm' | 'lg' | null, hasToken: boolean = false): string {
    if (value) {
      let mainPath: string = `${environment.api_url}${this.downloadPath}${value}`;
      let imgSize: string = size ? `size=${size}` : '';

      if (hasToken) {
        return `${mainPath}?token=${this.service.get(LocalStorageEnum.token)}&${imgSize}`;
        // return environment.file_static_url + value + '?token=' + this.service.get(LocalStorageEnum.token);
      } else {
        return `${mainPath}?${imgSize}`;
        // return environment.file_static_url + value;
      }
    }
    return '';
  }
}
